.navbar.navbar-light {
  background-color: #fff !important;
  background-size: contain;
  background-image: url(../../../Resources/Images/horizone.svg) !important;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 25px;
  @media only screen and (min-width: 768px) {
    background-position: center bottom -11px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 9px 40px;
    padding-bottom: 25px;
  }
  @media only screen and (min-width: 1800px) {
    background-position: center bottom -17px;
  }

  select {
    display: none;
    appearance: none;
    padding: 0px 4px;
    border-radius: 3px;
    border: 1px solid;
    @media (min-width: 768px) {
      display: block;
    }
  }

  .navbar-brand {
    color: #000 !important;
    margin: 0px auto;
    text-transform: uppercase;

    img {
      width: 100px;
    }
  }

  .menu {
    width: 20px;
  }
  .toggle-group img {
    width: 20px;
  }
  .navbar-toggler {
    border: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    display: block !important;
  }
}

.header-wrapper {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100%;

  img {
    width: 20px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .navbar.navbar-light .filter-tog {
    display: none !important;
  }
}
