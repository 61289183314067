.massage-list {
  padding-bottom: 100px;
}
.massege-item {
  padding: 10px;
  margin-bottom: 10px;
  background: #fbfbfb;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  display: flex;
  border: 1px solid #dedcdc;

  .icon {
    box-shadow: inset 1px 0px 2px rgb(105, 101, 101);
    max-width: 100%;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 0px;
    margin-right: 15px;
    flex: none;
    img {
      max-width: 100%;
    }
  }

  a.descripton{
    color: #18a9bd;
    font-weight: bold;
  }
  .descripton {
    white-space: pre-line;
    p {
      margin-bottom: 0px;
      font-size: 14px;
    }
    p.descripton {
      // font-family: "Handlee", cursive;
      padding: 5px 0;
      
    }
    p.name {
      font-weight: bold;
      margin-bottom: 3px;
    }

    span {
      margin-bottom: 0px;
      font-size: 14px;
    }
    .descripton span {
      // font-family: "Handlee", cursive;
      letter-spacing: -.2px;
    }
  }

  .display-text-group {
    span {
      margin-bottom: 0px;
      font-size: 14px;
    }
    .displayed-text {
      // font-family: "Handlee", cursive;
      word-break: break-all;
    }
    .read-more-button {
      font-weight: bold;
      margin-bottom: 3px;
    }
  }

  ul li {
    float: left;
    margin-right: 15px;
    margin-top: 0px;
    img {
      max-width: 100%;
      width: 20px;
      height: 18px;
    }

    &.translate {
      // position: absolute;
      // right: 10px;
    }
  }
}

.list-results {
  li {
    list-style-type: none;
  }
}

.option-panel {
  @media (min-width: 768px) {
    // display: none;
  }

  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  a.active {
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      display: block !important;
      -moz-box-shadow: inset 1px 0px 3px #8a8888;
      -webkit-box-shadow: inset 1px 0px 3px #8a8888;
      box-shadow: inset 1px 0px 1px #000;
      background-color: #26a9bc;
      border-radius: 100%;
      margin: 5px auto;
    }
  }
  a:first-child {
    border-right: 1px solid #ddd;
  }
  a:last-child {
    border-left: 1px solid #ddd;
  }
  a {
    background-color: #fbfbfb;
    border-top: 1px solid #ddd;
    padding: 10px;
    display: inline-block;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    .icon {
      width: 30px;
      height: 30px;
      margin: 0px auto;
      margin-bottom: 5px;
      img {
        width: 100%;
      }
    }
  }
}

.input-list {
  // border-top: 1px solid #ddd;
  // border-bottom: 1px solid #ddd;
  padding: 15px;
  .input- {
    position: relative;
    img {
      position: absolute;
      right: 15px;
      top: 8px;
      color: #c7c7c7;
      width: 8px;
    }
  }
  input {
    border: none !important;
    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
    &:active {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  label {
    font-size: 14px;
  }
}

textarea.form-control,
textarea.form-control:focus,
textarea.form-control:active {
  border: 1px solid #d2d8dd !important;
  box-shadow: none !important;
  outline: none !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #26a9bc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #26a9bc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

a.inactive {
  color: #ccc;
}

.navbar {
  i {
    color: #000000;
  }
}

.serach-panel {
  position: relative;
  input {
    padding-left: 30px;
    background-color: #efefef;
    border: none !important;
  }
  i {
    position: absolute;
    top: 11px;
    left: 25px;
    color: #b9b9b9;
  }
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #000000;
}

.navbar-dark .navbar-nav .nav-item.active,
.navbar-dark .navbar-nav .nav-item:focus {
  color: #000000;
  background-color: transparent;
  font-weight: bold;
}

.navbar-nav .nav-item {
  margin-bottom: 5px;
}

.tag-options {
  padding: 5px 10px;
  background-color: #efefef;
  display: inline-block;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
  i {
    font-size: 10px !important;
    color: #000000;
    margin-left: 5px;
  }
}

.tags-panel {
  margin-top: 10px;
}

::placeholder {
  color: #b9b9b9 !important;
}

.search-results {
  .main-result {
    margin-bottom: 0px;
    font-size: 14px;
  }
  span {
    display: block;
    font-size: 14px;
    color: #999999;
    padding-left: 18px;
  }
  i {
    color: #000;
    font-size: 12px;
    margin-right: 5px;
  }
  i.fa-plus-circle {
    font-size: 12px;
    color: #999999;
    float: right;
  }
}

.toggle-group {
  img {
    width: 20px;
  }
}

.image-panel {
  img {
    max-width: 100%;
    position: relative;
    top: -26px;
    z-index: 99999;
  }
}

.flag {
  width: 20px;
  height: 18px;
}

.green-txt {
  font-size: 12px;
  color: #26a9bc;
}

.radio-btn-container {
  display: flex;
  cursor: pointer;
  @media (max-width:600px) {
    flex-direction: column;
    
  }
}

.radio-btn {
  margin-right: 10px;
  position: relative;
  padding: 15px;
  border-radius: 4px;
  height: 220px;
  width: 100%;
  text-transform: uppercase;
  background-size: cover;
  color: #18a9bd;
  font-weight: 700;
  background-position: center;
  &:hover{
    color: #18a9bd;
  }
  @media (max-width:600px) {
    margin-bottom: 10px;
  }
  &:first-of-type{
    background-image: url(../../../../Resources/Images/bb.jpg);
  }
  &:nth-of-type(2){
    background-image: url(../../../../Resources/Images/wt.jpg);
  }
  &:nth-of-type(3){
    background-image: url(../../../../Resources/Images/bl.jpg);
  }
  input{
    visibility: hidden;
    &:checked + div:after{
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #18a9bd;
      content: '';
      border-radius: 50%;
      top: -25px;
      left: 0px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../../../Resources/Images/check.svg);
    }
  }
  div{
    position: absolute;
    bottom: 12px;
  p{
    font-weight: bold;
    margin-bottom: 0;
    font-size: 15px;
  }
  a{
    color: #18a9bd;
    font-weight: bold;
    
  }
}
}


.btn-container {
  display: flex;
  // width: 200px;
  justify-content: flex-end;

  flex-direction: row;
 
  // align-items: flex-end;
  // margin-left: auto;
}

.d-value{
.btn.btn-primary {
  background: #e8e8e8;
  margin-left: 5px;
  color: #212323;
  border: 1px solid #ccc9c9;
  &:hover{
    background: #fff !important;
  }
  &:active{
    color: #212323 !important;
  }

}
.selc{
  background: rgba(23, 169, 189, 0.9) !important;
  border-color: #32b2c3 !important;
  &:hover{
    background: rgba(23, 169, 189, 0.9) !important;
  }
}
.clean{
  background: #fff !important;
  &:hover{
    background: #fff !important;
  }
}
}