.donation {
  .mo-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #f9f9f9;
    font-weight: 600;
    border-bottom: 1px solid #e4e4e4;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: normal;
      cursor: pointer;
      @media(max-width: 540px){
        font-size: 12px;
      }
    }
  }

  small{
    display: block;
    margin-top: 20px;

  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #222;
    margin-bottom: 15px;
  }

  .MuiDialogContent-root:first-child {
    padding: 0;
  }

  .sec {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
    text-decoration: underline;
  }
  .btn {
    box-shadow: none !important;
    margin-top: 30px;
  }

  .MuiDialogContent-root {
    padding: 30px;
  }

  .MuiDialog-paperWidthSm{
    max-width: 500px;
  }

}

.donation .MuiDialogContent-root {

  padding: 50px 50px;
  @media(max-width:768px){
  padding: 30px 20px;
  }
}
.MuiDialog-paperWidthSm {
  max-width: 550px;
}