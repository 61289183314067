.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drawer {
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  @media (min-width: 992px) {
    // left: unset;
    // right: 0;
    width: 40%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

  .drawer-header {
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @media (min-width: 768px) {
      background-color: transparent;
    }
    h3 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      @media (min-width: 768px) {
        display: none;
      }
    }

    button {
      position: absolute;
      right: 20px;
      width: 20px;
      @media (min-width: 768px) {
        top: 15px;
      }

      img {
        width: 100%;
      }
    }
  }

  .menu-wrapper {
    padding: 20px;
    text-align: left;
    height: 90vh;
    overflow: auto;

    .menu-group {
      margin-bottom: 15px;
      text-transform: uppercase;
      line-height: 24px;
      font-size: 12px;
      p {
        font-weight: bold;
        margin-bottom: 0;
      }
      .nav-link {
        display: inline-block;
        color: inherit;
        padding: 0;
      }
    }
  }
}

.toggle ~ .drawer {
  transform: translateX(0px);
}

.share-button {
  margin: 2px !important;
}

.tag-location {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  img {
    margin-right: 10px;
    opacity: 0.8;
  }
}

.modal {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.modal-propper {
  border: 2px solid #000;
  padding: 16px 32px 24px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.btn-notification {
  position: fixed !important;
  /* top: 60px; */
  /* left: 81px; */
  /* height: 100px; */
  z-index: 999;
  /* display: none; */
  margin-left: 267px !important;
}

.reply-amount {
  font-style: italic;
  color: #777777;
}

.MuiPopover-paper {
  overflow: visible !important;
  border-radius: 0 !important;
p{
  background-color: #18a9bd;
    padding: 10px 12px;
    border-radius: 0;
    color: #fff;
    font-weight: 600;
    position: relative;

   
}
&:after{
  content: '';
    width: 15px;
    height: 15px;
    background-color: #18a9bd;
    position: absolute;
    bottom: -5px;
    z-index: 10;
    transform: rotate(45deg);
    left: 76px;
}


}

.self-desc{
  text-overflow: ellipsis;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.thumbsContainer{
  display: 'flex';
  flex-direction: 'row';
  flex-wrap: 'wrap';
  margin-top: 16;
  max-height: 80px;
}

.thumb{
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
}

.thumb-inner{
  display: 'flex';
  min-width: 0;
  overflow: 'hidden';
}

.img{
  display: 'block';
  width: 'auto';
  height: '100%';
}

.MuiCircularProgress-root{ 
  position: absolute;
  margin-left: 272px;
  margin-top: -650px;
}

.tag-exsist {
  background-color: #53aabd;
}

.align-text-initail {
  text-align: initial;
}

.align-text-end {
  text-align: end;
}

.order-table {
  width: 98%;
  margin: 10px;
}


// body {
//   overflow: hidden;
// }

.typewrap{
  width: 100%;
  overflow: hidden;

  .tit{
    margin: 10px 0;
    margin-top: 20px;
  }
}

.side-banner{
  margin-top: 30px;
   a{
    background-position: left -60px center;
    background-image: url(./Resources/Images/si.png) !important;
    div{
      height: 260px;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: end;
      padding-bottom: 20px;
      p{
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      h1{
        margin-bottom: 16px;
      }
    }
   }
}