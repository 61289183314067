@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

$brand-primary: "#00cccc";

body,
.MuiTableCell-root {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  color: #333;
  font-weight: 300;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.btn {
  font-size: 15px;

  &.btn-primary {
    background: #00cccc;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: 600;
    color: #303437;
    text-align: center;
    border: 0;
    &:hover,
    &:focus,
    &:active {
      background: #00cccc !important;
      color: #003636;
    }
  }
  &:hover,
  &:focus,
  &:active {
    border: 0;
    box-shadow: none !important;
  }
}
button:focus {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
  }
}

.pt-80 {
  padding-top: 80px;
  @media (min-width: 991px) {
    max-width: 980px;
    padding-top: 120px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: 768px) {
    padding-top: 110px;
  }
}

.massege-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  display: flex;
  border: 1px solid #dedcdc;
  position: relative;
  box-shadow: 0px 1px 23px rgba(85, 85, 85, 0.1);

  .icon {
    box-shadow: inset 1px 0px 2px rgb(105, 101, 101);
    max-width: 100%;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 0px;
    margin-right: 15px;
    flex: none;
    border: 1px solid #e4e4e4;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      position: absolute;
      right: -6px;
      top: 4px;
      display: none;
      box-shadow: 0px 0px 1px 0px #696565;
    }

    img {
      max-width: 100%;
    }
  }
  .descripton {
    p {
      margin-bottom: 0px;
      font-size: 15px;
      // white-space: pre-line;
    }
    .descripton > span {
      padding: 5px 0;
      // white-space: pre-line;
      // display: inline-flex;
    }
    p.name {
      font-weight: bold;
      margin-bottom: 3px;
      text-transform: capitalize;
      letter-spacing: -0.3px;
      color: #212529;
    }
  }
  ul li {
    float: left;
    margin-right: 15px;
    margin-top: 0px;
    @media (max-width: 540px) {
      margin-right: 12px;
    }
    img {
      max-width: 100%;
      width: 20px;
      height: 18px;
    }

    &.translate {
      // position: absolute;
      // right: 15px;
    }
  }
}

.list-results {
  li {
    list-style-type: none;
  }
}

.filter-tog{
  display: none;
}

.option-panel {
  @media (min-width: 100px) {
    display: none;
  }

  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  a.active {
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      display: block !important;
      -moz-box-shadow: inset 1px 0px 3px #8a8888;
      -webkit-box-shadow: inset 1px 0px 3px #8a8888;
      box-shadow: inset 1px 0px 1px #000;
      background-color: #26a9bc;
      border-radius: 100%;
      margin: 5px auto;
    }
  }
  a:first-child {
    border-right: 1px solid #ddd;
  }
  a:last-child {
    border-left: 1px solid #ddd;
  }
  a {
    background-color: #fbfbfb;
    border-top: 1px solid #ddd;
    padding: 8px;
    display: inline-block;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    padding-bottom: 5px;
    word-break: break-word;
    .icon {
      width: 30px;
      height: 30px;
      margin: 4px auto;
      margin-bottom: 5px;
      img {
        width: 100%;
      }
    }
  }
}

.input-grat {
  padding-bottom: 100px;
}

.input-list {
  // border-top: 1px solid #efecec;
  // border-bottom: 1px solid #ddd;
  padding: 0px;
  .input-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    img {
      // position: absolute;
      right: 20px;
      top: 8px;
      color: #c7c7c7;
      width: 8px;
    }
  }
  input[type=text] {
    border: 1px solid #d2d8dd !important;
    padding-right: 30px;

    &:focus {
      border: 1px solid #d2d8dd !important;
      box-shadow: none !important;
      outline: none !important;
    }
    &:active {
      border: 1px solid #d2d8dd !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  label {
    font-size: 15px;
    margin-bottom: 0;
    padding: 4px 0;
  }
  .label-group {
    display: flex;
    align-items: center;
  }
}

textarea.form-control,
textarea.form-control:focus,
textarea.form-control:active {
  box-shadow: none !important;
  outline: none !important;
  resize: none;
  margin-bottom: 15px;
  padding: 15px;
  height: unset !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #01cccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #01cccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

a.inactive {
  color: #ccc !important;
}

.navbar {
  i {
    color: #000000;
  }
}

.serach-panel {
  position: relative;
  input {
    padding-left: 30px;
    background-color: #efefef;
    border: none !important;
  }
  i {
    position: absolute;
    top: 11px;
    left: 25px;
    color: #b9b9b9;
  }
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #000000;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #000000;
}

.navbar-dark .navbar-nav .nav-item.active,
.navbar-dark .navbar-nav .nav-item:focus {
  color: #000000;
  background-color: transparent;
  font-weight: bold;
}

.navbar-nav .nav-item {
  margin-bottom: 5px;
}

::placeholder {
  color: #b9b9b9 !important;
}

.search-results {
  .main-result {
    margin-bottom: 0px;
    font-size: 15px;
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 12px;
    color: #999999;
    padding-left: 13px;
  }
  i {
    color: #000;
    font-size: 12px;
    margin-right: 5px;
  }
  i.fa-plus-circle {
    font-size: 12px;
    color: #999999;
    float: right;
  }
}

.toggle-group {
  img {
    width: 20px;
  }
}

.image-panel {
  img {
    max-width: 100%;
    position: relative;
    top: -26px;
    z-index: 99999;
  }
}

.flag {
  width: 20px;
  height: 18px;
}

.green-txt {
  font-size: 12px;
  color: #26a9bc;
}

@media only screen and (max-width: 1200px) {
  .navbar.navbar-dark {
    background-position-y: bottom -7px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-nav .nav-item {
    padding-left: 10px;
  }
}

.location {
  font-size: 12px !important;
  margin-bottom: 5px !important;

  img {
    width: 8px;
    vertical-align: unset;
    margin-right: 3px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 860px;
  }
}

.ReactTags__suggestions {
  background-color: transparent;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  padding: 15px;
  padding-left: 0;
  li {
    margin-bottom: 5px;
  }
  mark {
    padding: 0;
    color: #056c7a;
    background: none;
  }
}

.serach-panel {
  .form-control:focus {
    box-shadow: none !important;
    border-color: rgb(105, 101, 101) !important;
    background-color: #f5f5f5;
  }

  .form-control {
    background-image: url("../Images/ser.svg");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center left 12px;
    padding-left: 39px;
    margin-bottom: 10px;
  }
}

.form-control {
  font-size: 15px;
}

.tagger-wrapper {
  .btn-link {
    color: #555;
    float: right;
    margin-bottom: 5px;
    margin-right: 0;
    padding-right: 0;
  }
  .ReactTags__selected {
    margin-top: 5px;

    span {
      a {
        margin-left: 5px;
      }
    }
  }
}

.tagger {
  overflow: auto;
  width: 100%;
  position: relative;

  .add-button {
    border: 1px solid #b1a9a9;
    padding: 1px 12px;
    position: absolute;
    top: 5px;
    border-radius: 3px;
    right: 4px;
    background-color: #fefefe;
    cursor: pointer;
  }
}

.splash {
  position: fixed;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  pointer-events: none;

  .splash-group {
    text-align: center;
    // background-image: url("../Images/horizone.svg");
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      background-size: 150%;
      background-position: center top 29px;
    }
    background-size: 160%;
    background-position: center top 15px;
    background-repeat: no-repeat;
    height: 120px;
    position: relative;
    max-width: 500px;
    img {
      width: 180px;
      // margin-top: 15px;
    }
    p {
      margin-bottom: 0;
      bottom: 10px;
      position: absolute;
    }
  }
}

.fading {
  @media (min-width: 768px) {
    // animation: none;
  }
  animation: fading 0.3s ease-in forwards;
}

@keyframes fading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.impact-header {
  p {
    font-weight: bold;
    color: #01cccc;
  }
}

.no-border {
  border: 0 !important;
}

small.italic {
  font-style: italic;
}

.track {
  font-weight: bold;
  color: #01cccc !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.MuiAlert-root {
  box-shadow: none !important;
}
.shareModal {
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px !important;
    // filter: grayscale(1);
    &:last-of-type {
      margin-right: 0px !important;
    }
  }

  .MuiDialog-paper {
    max-width: 100%;
    position: fixed;
    @media (min-width: 768px) {
      position: initial;
      max-width: 600px;
    }
    margin: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    .MuiDialogTitle-root {
      border-bottom: 1px solid #e4dddd;
      // padding: 8px 8px;
    }
    .MuiDialogContent-root {
      padding: 8px 8px !important;
    }

    h2 {
      font-size: 15px;
      font-weight: 500;
    }

    p {
      margin-bottom: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: auto;
      padding: 10px;
      svg {
        overflow: hidden;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }
    }
  }

  .MuiDialogActions-spacing {
    display: none;
  }
}

.header-wrapper {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  @media (max-width: 768px) {
    z-index: 8888;
  }
}

.terms {
  font-family: "Roboto Condensed", sans-serif;
  z-index: 99999 !important;
  .MuiDialogContent-root {
    padding-top: 40px !important;
  }
  .MuiDialog-paper {
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: 600px;
      padding: 30px;
      padding-bottom: 0px;
    }

    margin: 5px;
    h3 {
      font-size: 15px;
      font-weight: 600;
      font-family: "Roboto Condensed", sans-serif;
    }

    p {
      margin-bottom: 12px;
      font-size: 15px;
      font-family: "Roboto Condensed", sans-serif;
      svg {
        overflow: hidden;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
    }
  }
}

.link a {
  color: inherit;
  label {
    text-decoration: underline;
  }
}

.filter-menu {
  padding-top: 60px;
}

.input-img, .input-list {
  .form-control {
    background-color: transparent !important;
    text-align: left;
        height: 48px;
    margin-bottom: 15px;
  }
}

.resp-type {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiSwitch-root {
    margin-right: 0 !important;
  }
}

.reply-amount {
  small {
    font-size: 12px;
  }

  img {
    width: 8px;
  }
}

.ab-btn {
  position: fixed;
  top: 19px;
  z-index: 11;
  right: 30px;
  z-index: 8888;
  @media (min-width: 768px) {
    position: initial;
    display: block;
    text-align: right;
    z-index: 3;
  }
  &.rep {
    @media (min-width: 768px) {
      display: block;
      position: relative;
      text-align: right;
      right: unset;
      top: unset;
    }
  }
}

.reply-wrapper {
  position: relative;

  .ab-btn {
    @media (min-width: 768px) {
      position: absolute;
      top: unset;
      z-index: 1;
      font-weight: normal;
      color: #333;
      right: 30px;
      display: flex;
    }
  }
}

.massege-reply {
  border-bottom: 1px solid #e4e1e1;
  padding: 15px;
  @media (max-width: 767px) {
    position: fixed;
    z-index: 500;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 1px solid #dad9d9;
    background-color: #fff;
    padding: 15px;
  }

  textarea {
    -webkit-appearance: none;
    height: unset !important;
  }
}

.reply-wrapper {
  @media (max-width: 768px) {
    margin-top: -15px;
  }

  padding-bottom: 140px;
  .massege-item .icon {
    box-shadow: inset 1px 0px 2px #696565;
    max-width: 100%;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding: 7px;
    border: 1px solid #e4e4e4;
    margin-right: 15px;
    /* flex: none; */
    position: absolute;
    right: 10px;
    line-height: 18px;
    display: flex;
  }

  .massege-item {
    padding: 15px 30px;
    margin-bottom: 10px;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    display: flex;
    border: 0;
    border-bottom: 1px solid #e4e1e1;
    @media (max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
    }
    position: relative;
  }
}

.sidenav {
  width: 270px;
  margin-right: 30px;
  flex-shrink: 0;
  @media (max-width: 991px) {
    display: none !important;
  }
  @media (min-width: 1199px) {
    position: fixed;
  }

  .fl {
    @media (min-width: 1199px) {
      height: calc(100vh - 440px);
      overflow: auto;
      padding-top: 0 !important;
      padding-bottom: 15px !important;
    }
  }

  .menu a {
    display: flex;
    font-family: "Roboto Condensed", sans-serif;
    align-items: center;
    padding: 8px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 400;
    color: #666666;;
    // letter-spacing: .5px;
    word-break: break-word;
    font-size: 17px;
  

    &.active {
      background-color: #e5e5e5;
      color: #303437;
      position: relative;

      img {
        // filter: invert(1);
      }

      &:after {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        background-color: #00cccc;
        right: 19px;
        border-radius: 50%;
        top: calc(50% - 4px);
      }
    }
    .icon {
      width: 30px;
      margin-right: 20px;
      display: flex;
      height: 35px;
      img {
        width: 100%;
      }
    }
  }

  .menu-group {
    padding: 20px 0;

    p {
      font-weight: 600;
    }
    ul {
      line-height: 40px;
      li {
        a {
          font-size: 15px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          cursor: pointer;
          img {
            width: 5px;
          }
        }
      }
    }
  }
}

.msg-wrapper {
  position: relative;
  @media (min-width: 768px) {
    display: flex;
  }

  > .row {
    @media (min-width: 768px) {
      width: 100%;
    }
  }

  .input-grat {
    width: 100%;
    overflow: hidden;
  }
}

.side-filter {
  display: none;
  .filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    border-bottom: 1px solid #e8e6e6;
    border-top: 1px solid #e8e6e6;
    padding: 20px 10px;
    cursor: pointer;
    text-transform: uppercase;
    img {
      width: 15px;
    }
  }
}

.header-wrapper {
  ~ .pt-80 .sidenav {
    display: none;
  }
}

.reply {
  ~ .pt-80 .sidenav {
    display: block;
  }
}

.nav-cnt {
  margin: auto;

  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 980px;
    flex-direction: row-reverse;
  }
}

.world {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-bottom: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  img {
    width: 80%;
    margin: 0 auto;
  }
  .msg {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 20%;
    h4 {
      font-weight: 600;
      letter-spacing: -0.8px;
      margin-bottom: 0;
      font-size: 15px;
    }
  }
}

.nav-rep {
  @media (max-width: 767px) {
    margin: auto;
    width: 100%;
    max-width: unset;
  }
  max-width: 980px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-body {
  display: none;
  .filter-menu {
    padding-top: 0;

    .input-list {
      border: 0;
      padding: 15px 0 0 0;
    }
  }
}

.tag-options {
  margin-bottom: 5px;
  cursor: pointer;
  .removeClass {
    color: #fff !important;
    background-color: #000;
    width: 12px;
    align-items: center;
    justify-content: center;
    height: 12px;
    text-align: center;
    border-radius: 24px;
    font-size: 11px;
    line-height: 10px;
    cursor: pointer;
    display: inline-flex;
  }
}

.text-wrapper {
  padding: 0px 15px;
  padding-bottom: 80px;
  h5 {
    font-size: 15px;
    font-weight: bold;

    a {
      color: #17a9bd;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    // letter-spacing: -.5px;
  }

  .MuiButton-root {
    color: #ffffff !important;
    border: 0px solid !important;
    font-size: 15px !important;
    font-family: "Roboto Condensed", sans-serif !important;
    padding-left: 15px !important;
    text-transform: initial !important;
  }
}

.side-filter.toggle {
  .filter-body {
    display: block;
    border-bottom: 1px solid #e8e6e6;
    padding-bottom: 15px;
  }

  .filter-header {
    border-bottom: 0;
  }
}

.reply-title {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  border-bottom: 1px solid #f3f0f0;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    border: 0;
  }
  h5 {
    color: #17a9bd;
    font-size: 15px;
    font-weight: 600;
    @media (max-width: 768px) {
      display: none;
    }
  }

  img {
    width: 5px;
    margin-right: 10px;
  }
}

.lg-btn {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  img {
    width: 5px;
    margin-right: 10px;
  }
}

.MuiButton-root {
  color: #202020 !important;
  border: 1px solid !important;
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: bold !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.MuiTypography-h6 {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: bold !important;
}
.MuiTypography-body1 {
  font-family: "Roboto Condensed", sans-serif !important;
  font-size: 15px !important;
}

.italic {
  font-style: italic;
}

.bk-btn {
  @media (max-width: 768px) {
    display: none !important;
  }
  float: right;
  margin-bottom: 10px;
  img {
    width: 5px;
    margin-right: 10px;
  }
}

.MuiButton-textPrimary {
  color: #ffffff !important;
  background-color: #1d1f21 !important;
}

a,
.ab-btn,
select {
  cursor: pointer;
}

.MuiButton-textSecondary:hover {
  background-color: transparent !important;
}

@media (min-width: 768px) {
  .carousel-wrapper {
    background-color: rgba(17, 17, 17, 0.69);
    position: fixed;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding-top: 15px;
    width: 100%;
    > div {
      width: 550px;
      background-color: white;
      padding: 15px 30px;
      border-radius: 4px;
      padding-bottom: 30px;
      margin: auto;
      .carousel-group {
        text-align: center;
        width: 100%;
        overflow-x: hidden;
        padding-top: 122px;

        .carousel-inner {
          margin-bottom: 40px;
        }

        .carousel-indicators {
          margin-bottom: 0;
          bottom: -35px;
          li {
            background-color: #d6cdcf;
            &.active {
              background-color: #f6891f;
            }
          }
        }
        .carousel-caption {
          padding-bottom: 0;
          top: -120px;
          p {
            color: #111 !important;
          }
        }

        .carousel-item {
          height: 260px;
          border-radius: 4px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: bottom -30px center;
        }
      }
    }
    .btn {
      box-shadow: none !important;
    }
  }
}

@media (max-width: 768px) {
  .carousel-wrapper {
    padding-top: 15px;
    > div {
      width: 100%;
      max-width: 500px;
      margin: auto;
      .carousel-group {
        text-align: center;
        width: 100%;
        overflow-x: hidden;
        padding-top: 120px;

        .carousel-indicators {
          margin-bottom: 5px;
          bottom: -42px;
        }
        .carousel-caption {
          padding-bottom: 0px;
          top: -140px;
        }
      }
    }
  }
}

.charleft {
  float: right;
  font-size: 12px;
  color: #929292;
}

.ReactTags__suggestions {
  cursor: pointer;
}

.bk-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-mute {
  filter: grayscale(1);
  opacity: 0.2;
}

.empty-state {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50vh;

  img {
    width: 175px;
  }
}

.cnt-group {
  width: 100%;
  @media (min-width: 1199px) {
    width: calc(100% - 300px);
    position: absolute;
    right: 0;
  }
}

.inp-error {
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  right: 15px;
  color: #00cccc;
  background-image: url(../Images/er.svg);
  background-repeat: no-repeat;
  background-size: 13px;
  padding-left: 17px;
  background-position: center left;
}

.inp-waring {
  // position: absolute;
  bottom: 5px;
  font-size: 12px;
  right: 15px;
  color: #00cccc;
  background-image: url(../Images/er.svg);
  background-repeat: no-repeat;
  background-size: 13px;
  padding-left: 17px;
  background-position: center left;
}

.disabled {
  filter: grayscale(1);
}

.dis {
  color: #c3c3c3 !important;
  pointer-events: none;
  .icon {
    opacity: 0.5;
  }
}

.blocker {
  background-color: #01cccc;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px;
  color: #003636;
  font-weight: 500;

  @media (min-width: 768px) {
    background-color: #01cccc;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #003636;
    font-weight: 500;
    font-size: 12px;
    padding: 3px 12px;
    left: unset;
    border-radius: 3px 3px 0 0;
  }
}

.email-share-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.MuiTooltip-tooltip,
.MuiTooltip-arrow::before {
  background-color: #00cccc !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  color: #111 !important;
  text-align: center;

}

.stats {
  text-align: center;
  h4 {
    font-weight: 700;
    font-size: 36px;
    color: #717171;
    letter-spacing: -1px;
    margin-bottom: 0;
  }
  p {
    font-weight: 700;
    color: #00cccc;
    text-transform: uppercase;
    @media (max-width:768px) {
      font-size: 14px;
      
    }
  }
}

.stc-wrapper {
  padding-bottom: 80px;

  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .bold,
  h5 {
    font-weight: bold;
    font-size: 15px !important;
    letter-spacing: -0.5px;
  }

  strong {
    font-weight: normal;
  }

  ul {
    padding: 15px;
    list-style: disc;
  }

  .semi-bold {
    font-weight: 600;
  }

  h6 {
    font-size: 15px;
    font-weight: 600;
  }
}
.email-share {
  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    margin: 0;
    flex-grow: 1;
    height: 100vh;
    border-radius: 0;
    max-height: unset;
    @media (min-width: 768px) {
      max-width: 40%;
      height: unset;
      border-radius: 4px;
    }
  }
  .mo-header {
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    font-weight: 600;
    text-transform: uppercase;
    @media (min-width: 768px) {
      position: relative;
    }

    img {
      width: 20px;
    }
  }
  .MuiDialogContent-root {
    padding: 30px;
    padding-top: 90px;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 30px;
    }

    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      font-size: 15px;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding: 26px 0px;
      border-top: 0;
      border-color: #e9ecef;
      &:focus {
        box-shadow: none;
      }
    }
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #e9ecef00;
      opacity: 1;
    }
  }
  .btn.btn-primary {
    background: #00cccc;
    box-shadow: none;
    border-radius: 3px;
    font-weight: 700;
    color: #003636;
    text-align: center;
    border: 0;
    height: 50px;
  }
}

.story {
  .st-img {
    @media (max-width: 767px) {
      width: 35%;
      margin-right: 5px;
    }
    width: 27%;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    margin-right: 10px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #e4e4e4;
    img {
      width: 43px;
    }
    cursor: pointer;
  }

  .more {
    border: 1px solid;
    padding: 3px 6px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
    display: inline-block;
    color: #303437;
  }
  p {
    font-family: "Roboto Condensed", sans-serif !important;
  }
  .name {
    a {
      color: #00b2b3 !important;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
    }
    font-weight: bold;
    margin-bottom: 3px;
    @media (max-width: 767px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .st-wrap {
    padding: 10px;
    width: 73%;
    @media (max-width: 767px) {
      width: 65%;
    }
    p.name {
      font-weight: 400;
      margin-bottom: 3px;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  p.descripton {
    @media (max-width: 767px) {
      height: 45px;
      overflow: hidden;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
  cursor: not-allowed;
}

.bt-0 {
  border-top: 0 !important;
}
.bb-0 {
  border-bottom: 0 !important;
}

.give-little {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    margin-top: 10px;
    position: relative;
    text-transform: uppercase;

    &.disabled {
      filter: grayscale(0);
      opacity: 1;
      background-color: #dadbda !important;
      color: #abaaaa !important;
      cursor: not-allowed;
      box-shadow: none;
    }

    .blocker {
      @media (max-width: 767px) {
        background-color: #01cccc;
        position: absolute;
        bottom: unset;
        left: unset;
        right: 0;
        padding: 1px 4px;
        color: #fff;
        font-weight: 700;
        font-size: 10px;
        top: 0;
      }
    }
  }
  .btn.btn-primary:hover {
    // color: rgb(206, 199, 199);
  }
}

.cp {
  cursor: pointer;
}

.MuiDialog-paper {
  margin: 15px !important;
  position: relative;
  overflow-y: auto;
}

.mw-5 {
  max-width: 500px;
  width: 100%;
  padding: 40px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 15px;
  }
}

.mo-header span {
  cursor: pointer;
  @media (max-width: 767px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
  }
}

.vid {
  .MuiDialogContent-root {
    padding: 5px !important;
    max-height: 374px;
    overflow: hidden;
    height: 100%;
  }
}

.sway {
  span {
    text-transform: uppercase;
  }
}

.email-share .MuiDialog-paper {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .vid {
    .MuiDialog-paper {
      max-width: 90%;
      margin: 0 !important;
      height: unset;
      max-width: 90%;
    }
    .mo-header {
      position: relative !important;
    }
    .MuiDialogContentText-root {
      margin-bottom: 0 !important;
    }
  }
  .story {
    .MuiDialog-paper {
      max-width: 99%;
      margin: 0 !important;
      height: 99vh;
      max-width: 99%;
    }
  }
}

.btn.btn-primary {
  box-shadow: none !important;
}

.bb {
  border-bottom: 1px solid #efecec;
}

.done-btn {
  display: block;
  width: 100%;
  text-align: right;
  padding: 15px;
  cursor: pointer;
}
.pb-5,
.py-5 {
  padding-bottom: 5rem !important;
}

.name-loc {
  display: flex;
  padding-top: 5px;
  align-items: center;
  @media (max-width: 767px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .location {
    font-size: 12px !important;
    margin-bottom: 0px !important;
    display: flex;
    margin-left: 8px;
    align-items: center;
    @media (max-width: 767px) {
      // margin-left: 0;
    }
  }
}

.msg-tag-loc {
  display: none;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
  .tag-group {
    display: flex;
    flex-wrap: wrap;
    span {
      background-color: #e1e1e1;
      padding: 2px 6px;
      font-size: 12px;
      display: inline-block;
      border-radius: 3px;
      margin-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
      margin-bottom: 2px;
    }
  }
  .location {
    font-size: 12px !important;
    margin-bottom: 0px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-left: 0;
      display: none;
    }
  }
}

.rec-name {
  // font-family: "Handlee", cursive !important;
}

.rep-count {
  margin-left: 5px;
  cursor: pointer;
  // font-weight: 600;
}

.story {
  .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    margin: 0;
    flex-grow: 1;
    height: 100vh;
    border-radius: 0;
    max-height: unset;
    @media (min-width: 768px) {
      max-width: 70%;
      height: unset;
      border-radius: 4px;
    }
  }
}

.story .MuiDialogContent-root {
  padding: 0;
  padding-top: 0px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .story .MuiDialogContent-root {
    padding: 5px !important;
    padding-top: 53px;
    padding-bottom: 15px;
  }
}

.msg-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex-direction: column-reverse;
  }
  .name-loc {
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: flex-end;
    @media (max-width: 767px) {
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      justify-content: flex-end;
    }
    .name {
      @media (max-width: 767px) {
        white-space: nowrap;
        max-width: 45%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.name-loc {
  flex-wrap: wrap;
  .location {
    @media (max-width: 767px) {
      max-width: 45%;
    }
    span {
      @media (max-width: 767px) {
        width: 90%;
      }
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  p.name {
    @media (max-width: 767px) {
      white-space: nowrap;
      max-width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.carousel-wrapper .carousel-inner img {
  border-radius: 0;
  width: 150px;
  margin: auto;
}

.story {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 23px rgba(85, 85, 85, 0.12);
}

.option-panel a:nth-child(2) {
  border-right: 1px solid #ddd;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 15px 24px;
  background-color: whitesmoke;
  h2 {
    font-size: 15px;
    letter-spacing: -0.3px;
  }
}

.MuiDialogActions-root {
  border-top: 1px solid #ece7e7;
}

.mapdata {
  padding: 15px;
  li {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efeaea;
    &:last-of-type {
      border: 0;
    }
    span {
      display: inline-flex;
      width: 20px;
      height: 22px;
      background-color: gainsboro;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      margin-right: 10px;
      font-weight: 600;
    }
    img {
      float: right;
      width: 10px;
      opacity: 0.5;
      margin-top: 5px;
    }
  }
}

.msg-img {
  width: 100%;
  margin-top: 5px;
  img {
    width: 100%;
    border: 1px solid #d9d9d9;
  }

  div.mimg {
    width: 100%;
    height: 250px;
    background-size: contain;
    border-radius: 4px;
    margin-top: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #303437;
  }

  > div {
    margin-top: 5px;
  }
}

.input-list-img {
  display: flex;
  flex-direction: column;
  @media (max-width: 540px) {
    flex-direction: column;
  }
  .imgas {
    border-radius: 2px;
    border: 1px solid rgb(234, 234, 234);
    margin-bottom: 0px;
    margin-right: 0px;
    width: 100%;
    min-height: 150px;
    padding: 4px;
    background-image: url("../Images/image.svg");
    background-size: 45px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    @media (max-width: 540px) {
      width: 100%;
      margin-top: 5px;
      height: 220px;
    }

    video {
      width: 100%;
      height: 100%;
      @media (max-width: 540px) {
        height: 209px;
        width: 100%;
      }
    }

    img {
      margin: auto;
    }

    .closeb {
      position: absolute;
      right: 9px;
      font-size: 15px;
      background-color: #00cccc;
      width: 20px;
      height: 20px;
      display: flex;
      top: 9px;
      padding: 0;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;
      border-radius: 23px;
      color: white;
      font-weight: bold;
      border: 0;
      z-index: 20;
      @media (max-width: 540px) {
        top: 2px;
        right: 2px;
      }
    }
  }
  .dropzone {
    height: 100%;
    cursor: pointer;
    border-width: 1px;
    border-radius: 2px;
    border-color: #d8d8d8;
    justify-content: center;
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;

    p {
      margin-bottom: 0 !important;
      color: #303437;
      text-align: center;
    }
  }
}

.b-0 {
  border: 0 !important;
}

.tit {
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  color: #00cccc;
  text-transform: uppercase;
  // font-family: "Roboto";
  width: fit-content;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    height: 20px;
    background-image: url(../Images/squiggle.png);
  background-size: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left 7px center;
    width: 500px;
  }
  
}

.track {
  color: #003636 !important;
  background-color: #00cccc !important;
  border-radius: 4px;
  &.inactive {
    border-color: #4e555b;
    color: #fff !important;
    filter: grayscale(1);
    pointer-events: none;
  }

  border: 0 !important;
  padding: 10px 15px !important;
}

.MuiCircularProgress-colorPrimary {
  color: #00cccc !important;
}

.se {
  &:focus {
    border-color: #00cccc !important;
    box-shadow: none !important;
    background-image: url("../Images/ser1.svg");
  }
  background-image: url("../Images/ser.svg");
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px;
  background-position: center left 7px;
  padding-left: 40px;
}

.dd-toggle {
  position: absolute;
  right: 15px;
  top: 9px;
  cursor: pointer;
  img {
    width: 20px;
  }
}

.dd {
  background-color: white;
  border: 1px solid #e6e6e6;
  // width: 120px;
  padding: 10px 15px;
  border-radius: 2px;
  box-shadow: 0 1px 9px #d0d0d047;
  position: absolute;
  right: 8px;
  text-align: center;
  display: none;
  top: 35px;

  &:after {
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    content: "";
    top: -6px;
    z-index: 5;
    border: 1px solid #e6e6e6;
    transform: rotate(45deg);
    right: 12px;
    border-bottom: 0;
    border-right: 0;
  }
  span {
    cursor: pointer;
    display: block;
    font-size: 12px;

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  &.toggle {
    display: block;
  }
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 0;
  margin-right: 0;
  width: 100%;
  height: 139px;
  padding: 0px;
  justify-content: center;
  @media (max-width: 540px) {
    height: 210px;
  }
}

.lf {
  font-size: 15px !important;
}

.tag-exsist {
  background-color: #53aabd;
  color: #fff;
}

.sh-btn {
  background-color: #00cccc;
  margin-bottom: 5px;
  color: #003636 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  letter-spacing: .5px;

  img {
    // filter: invert(1);
  }
}

// ::placeholder {
//   color: #303437 !important;
// }

.text-primary {
  color: #00cccc !important;
}

.map-wrap {
  img {
    width: 250px;
    @media (max-width: 540px) {
      width: 200px;
    }
  }
  h4 {
    font-size: 24px;
  }
  .d-flex {
    display: flex !important;
    width: 322px;
    margin: 12px auto;
    justify-content: space-between;
    transform: translateX(20px);
    @media (max-width: 540px) {
      width: 80%;
      padding-bottom: 60px;
      justify-content: space-around;
    }
    @media (max-width: 400px) {
      width: 100%;
      padding-bottom: 60px;
    }

    div:nth-of-type(2) {
      margin-left: 49px;
    }
  }
}

.search-results > img {
  float: right;
  margin-top: 15px;
  width: 120px;
}

.br {
  border-right: 1px solid #efecec;
  @media (max-width: 991px) {
    border-right: 0;
  }
}

video {
  width: 100%;
  height: 100%;
  border: 1px solid #efecec;
}

.descripton a {
  color: #00b2b3 !important;
}

.mo-header {
  span.bk {
    cursor: pointer;
    font-weight: 600;
    padding: 0 15px 15px;
    display: flex;
    color: #00cccc;
  }
}

.descripton.dscp {
  margin-top: -15px;
}

.d-text {
  span,
  a {
    color: #00b2b3;
  }
  img {
    width: 160px;
    clear: both;
    display: block;
    margin-top: 30px;
  }
}
.navbar .menu-group{
  position: absolute;
    right: 15px;
    border: 1px solid;
    font-size: 14px;
    padding: 1px 9px;
    border-radius: 3px;
}
.nav-cnt{
  position:relative;
}

.giftwrapper{
  display: flex;
  @media (max-width:540px) {
    flex-direction: column;
  }
  .giftvideo{
    max-width: 200px;
    display: flex;
    width: 100%;

    @media (max-width:540px) {
      max-width: 100%;
    }

    img{
      width: 100%;
      object-fit: cover;
    }
  }
  .giftdsc{
    text-align: left;
    padding-left: 20px;
    @media (max-width:540px) {
      padding:0;
      margin-top: 15px;
    }
    h3{
      margin-bottom: 0px;
    }
    small{
      color: #a3a5a5;
    line-height: 16px;
    display: block;
    font-weight: 300;
    }
  }
  h2{
    letter-spacing: -1px;
  }
}

.gift{
  text-align: left;
  max-width: 620px;
}

.inp-error {
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  right: 23px;
}

.input-border{
  border: 1px solid #d2d8dd;
  padding: 8px;
  margin-top: 14px;
  border-radius: 4px;
}

.range-slider__wrap{
  width: 100%;
  input{
    width: 100%;
    border:0;
    padding: 0;
  }
}

.rd,.sd{

  h3{
    text-transform: uppercase;
    color: #00cccc;
  }
  a{
    color: #00cccc;
  }

  p{
    margin-bottom: 0px;
  }

}

.gifmsg{
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: whitesmoke;
    word-break: break-all;
    position: relative;
    padding-bottom: 25px;
  p{
    margin-bottom: 0;
    word-break: break-word;
  }
  a{
    position: absolute;
    right: 8px;
    color: #01cccc;
  }
}

.giftot{
  background-color: #e8ffff;
    padding: 20px;
    width: 100%;
    text-align: right;
    letter-spacing: -1px;
    font-weight: 600;
    text-transform: uppercase;
}

.str{
  display: flex;
  color: #8b8b8b;
  padding: 10px;
  border: 1px solid #ddd6d6;
  font-size: 14px;
  align-items: center;
  background-color: whitesmoke;
  line-height: 17px;

    img{
      width: 126px;
      height: fit-content;
      max-height: 33px;
      margin-right: 10px;
    }
}

.range-slider__wrap{
  margin-top: 25px;
  &::after{
    position: absolute;
    content: '$0';
    z-index: 4;
    top: -13px;
    left: 3px;
  }
  &::before{
    position: absolute;
    content: '$10';
    z-index: 4;
    top: -13px;
    right: 3px;
  }
}

.btn-primary:not(:disabled):not(.disabled):active{
  color: #024646 !important;
}
.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
  background: #01d7d7 !important;
  color: #024646 !important;
}

.thank{

  .logo{
    width: 129px;
    margin: auto;
    display: block;
    margin-bottom: 33px;
  }
  max-width: 775px;
    width: 100%;
    margin: auto;
    padding: 40px;
}

.nav-cnt select{
  font-weight: 300;
  height: 25px;
}

.btn{
  text-transform: uppercase !important;
}

.char{
  span{
    color: #607d8b !important;
  }
}

.d-radio{
  display: flex;
  justify-content: space-between;
  > div{
    display: flex;
    background-color: #ececec;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 4px;
    border-radius: 6px;
    font-weight: 600;
    text-indent: -10px;
    cursor: pointer;
    // padding: 5px;
    &:hover{
      background-color: #00cccc;
    }
    &:first-of-type{
      margin-left: 0;
    }
    &:last-of-type{
      margin-right: 0;
    }
    input[type="radio"]{
      visibility: hidden;
    }
    label{
      width: 100%;
      text-align: center;
      cursor: pointer;
    }
  }
  .active{
    background-color: #00b2b3;
  }
}

.d-wrapper{
  input[type=text]{
    height: 40px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.form-control{
  font-weight: 300 !important;
}

.smile{
  position: absolute;
  left: 40px;
  top: 2px;
  width: 150px;

  @media (max-width:600px) {
    position: absolute;
    left: 12px;
    top: 2px;
    width: 150px;
    transform: scale(0.7);
    
  }

  .like{
    width: 30px;
    position: absolute;
    left: 115px;
    top: 5px;
  }
}

.ban{
  background-size: cover;
  border-radius: 4px;
  margin-bottom: 15px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  transition: all .3s ease-in;
  &:hover{
    filter: brightness(1.2);
  }
  &.uk{
    background-image: url(../Images/uk.jpg);
  }
  &.ses{
    background-image: url(../Images/ses.jpg);
  }
  &.sesq{
    background-image: url(../Images/sesq.jpg);
  }
  &.smilee{
    background-image: url(../Images/smile.jpg);
  }
  div{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: #fff;
    text-align: right;
    padding: 10px 15px;
    p{
      margin-bottom: 0;
    }
    h1{
      font-size: 28px;
      letter-spacing: -1px;
      margin-bottom: 5px;
  }
  .btn-secondary,.btn-secondary:active,.btn-secondary:focus {
    color: rgb(18, 13, 13) !important;
    background-color: #01cccc !important;
    border-color: #01cccc !important;
    border: 0;
    display: block;
    margin-bottom: 3px;
    cursor: pointer;
    overflow: hidden;
}
  }
}

.carousel-indicators li {
  width: 20px;
}

.titsg{
  font-weight: 600;
  color: #7f8c8d;
}

.d-radio .active {
  background-color: #01cccc;
}

.dscl{
  padding: 12px;
  border: 1px dashed #d2cece;
}

.rep-count{
  display:none;
}