.carousel-wrapper {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-top: 120px;
  width: 100%;
  left:0;

  .carousel-item {
    height: 40vh;
    background-size: cover;
    &.item-one {
      background-image: url("../../../../Resources/Images/grt-slide-1.jpg");
    }
    &.item-two {
      background-image: url("../../../../Resources/Images/grt-slide-3.jpg");
    }
    &.item-three {
      background-image: url("../../../../Resources/Images/grt-slide-3.jpg");
    }
    background: none !important;
  }

  .carousel-group {
    text-align: center;
    width: 100%;
  }

  .carousel-inner {
    overflow: unset;
    margin-bottom: 60px;
    img {
      border-radius: 15px;
    }
    p{
      padding: 84px 18px;
    word-break: break-word;
    font-size: 16px;
    }

    ul{
      margin: 4px 40px;
      li{
        display: flex;
        align-items: center;
        img{
          width: 80px;
          margin: unset;
          margin-right: 10px;

        }
        span{
          color:#111;
          text-align: left;
          font-size: 16px;
        }
      }
    }

    .carousel-caption {
      bottom: unset;
      top: -140px;
      width: 100%;
      right: 0;
      left: 0;
      padding: 20px;
      h3 {
        font-size: 16px;
        font-weight: 800;
        color: #f6891f;
        margin-bottom: 15px;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 45%;
    span {
      visibility: hidden;
    }
  }

  .carousel-indicators {
    bottom: -50px;
    li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 8px;
      border-radius: 50%;
      height: 8px;
      margin-right: 8px;
      margin-left: 8px;
      background-color: #c2c2c2;
      opacity: 1;

      &.active {
        background-color: #f6891f;
      }
    }
  }

  .btn.btn-primary {
    background: #d4d1d1;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: 600;
    color: #000001;
    text-align: center;
    border: 0;
    &:hover{
      background: #d4d1d1 !important;
      color: #000001;
    }
    &:focus{
      background: #d4d1d1 !important;
      color: #000001;
    }
}
}
